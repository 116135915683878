import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { NgModule } from '@angular/core';

import { APP_INSTANCE_ID, Environment, WindowRefService } from '@gv/ui/core';
import { generateUuid } from '@gv/utils';

import { ApiService } from '../../service/api/api.service';
import { APP_CONFIG } from '../../entity/token/app.config';
import { appConfig } from '../../entity/constant/config';
import { INTERCOM_PRODUCT_TOURS } from '../../entity/token/intercom-tours';
import { JWT_INTERCEPTOR_IGNORABLE_ITEMS } from '../../entity/token/jwt-interceptor-ignorable-items';
import { JWT_INTERCEPTOR_WHITELIST } from '../../entity/token/jwt-interceptor-whitelist';
import { productionTours } from '../../entity/constant/3rd-party-service/production-tours';
import { testTours } from '../../entity/constant/3rd-party-service/test-tours';

export function getBaseHrefFactory(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM() || '';
}

@NgModule({
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHrefFactory,
      deps: [PlatformLocation],
    },
    {
      provide: APP_CONFIG,
      useValue: appConfig,
    },
    {
      provide: APP_INSTANCE_ID,
      useFactory: () => {
        return generateUuid();
      },
    },
    {
      provide: JWT_INTERCEPTOR_WHITELIST,
      useFactory: (apiService: ApiService) => {
        return [
          apiService.baseUrl,
          apiService.baseUrl + '/app/beacon',
          apiService.baseUrl + '/app/client-log',
          apiService.baseUrl + '/app/feedback',
          apiService.baseUrl + '/user',
          apiService.baseUrl + '/user/auth',
          apiService.baseUrl + '/user/request/password',
          apiService.baseUrl + '/user/request/verify',
          apiService.baseUrl + '/user/request/password/change',
          apiService.baseUrl + '/user/verification',
          /.*\.amazonaws\.com/,
          /.*sentry\.io/,
        ];
      },
      deps: [ApiService],
    },
    {
      provide: JWT_INTERCEPTOR_IGNORABLE_ITEMS,
      useFactory: (apiService: ApiService) => {
        return [
          apiService.baseUrl + '/user/auth/logout',
          apiService.baseUrl + '/user/auth/token',
          apiService.baseUrl + '/user/notification/auth',
        ];
      },
      deps: [ApiService],
    },
    {
      provide: INTERCOM_PRODUCT_TOURS,
      useFactory: (windowRef: WindowRefService) => {
        return !!windowRef &&
          !!windowRef.nativeWindow &&
          (windowRef.nativeWindow._environment === Environment.Production ||
            windowRef.nativeWindow._environment ===
              Environment.ElectronProduction)
          ? productionTours
          : testTours;
      },
      deps: [WindowRefService],
    },
  ],
})
export class ConfigModule {}
