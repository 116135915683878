import { InjectionToken } from '@angular/core';

import type { JwtInterceptorWhitelistItem } from '../type/http/jwt-interceptor-whitelist-item';

/**
 * These items are considered as requests which don't require token.
 */
export const JWT_INTERCEPTOR_WHITELIST = new InjectionToken<
  JwtInterceptorWhitelistItem[]
>('jwtInterceptorWhitelist');
