import { InjectionToken } from '@angular/core';

import type { JwtInterceptorIgnorableItem } from '../type/http/jwt-interceptor-ignorable-item';

/**
 * These items are considered as items which must use token but do not initiate the refreshing.
 * These items must wait if current token is refreshing to be updated.
 */
export const JWT_INTERCEPTOR_IGNORABLE_ITEMS = new InjectionToken<
  JwtInterceptorIgnorableItem[]
>('jwtInterceptorIgnorableItems');
